import React, { useEffect, useState } from "react";

const Food = ({ highlightDay }) => {
    const [foodData, setFoodData] = useState([]);
    const [matchingFoods, setMatchingFoods] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 3;

    // Fetch food data from the backend
    useEffect(() => {
        const fetchFoodData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/chart/food`);
                const result = await response.json();
                if (response.ok) {
                    setFoodData(result);
                }
            } catch (error) {
                console.error("Error fetching food data:", error);
            }
        };
        fetchFoodData();
    }, []);

    // Find foods matching the highlight day's phase
    useEffect(() => {
        if (!highlightDay || !foodData.length) return;

        const matching = foodData.filter(food => food.phase === highlightDay.phase);
        setMatchingFoods(matching);
    }, [highlightDay, foodData]);

    //scroll food
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentFoods = matchingFoods.slice(startIndex, endIndex);

    // Handlers for pagination
    const handleNext = () => {
        if (endIndex < matchingFoods.length) {
            setCurrentPage(currentPage + 1);
        }
    };
    const handlePrev = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <div className="lato" style={{ color: '#fff' }}>
            <h3>What To Eat:</h3>

            {matchingFoods.length > 0 ? (
                <div style={{}}>
                    <div className="pagination-controls">
                        <button
                            className="pagination-button"
                            onClick={handlePrev}
                            disabled={currentPage === 0}
                        >
                            &lt;
                        </button>

                        {currentFoods.map((food, index) => (
                            <div className="food-item" key={index}>
                                <div className="tooltip-container">
                                    <img
                                        src={`https://greattakes.s3.us-east-2.amazonaws.com/foodIcons/${food.image}.png`}
                                        alt={food.food}
                                        className="food-image"
                                    />
                                    <p className="food-name">{food.food}</p>

                                    <div className="tooltip">
                                        <p style={{ fontWeight: 600 }}>How it helps:</p>
                                        <ul>
                                            <li>{food.purpose1}</li>
                                            {food.purpose2 && <li>{food.purpose2}</li>}
                                        </ul>
                                        <p style={{ fontWeight: 600 }}>Nutrients:</p>
                                        <ul>
                                            <li>{food.supplement1}</li>
                                            {food.supplement2 && <li>{food.supplement2}</li>}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}

                        <button
                            className="pagination-button"
                            onClick={handleNext}
                            disabled={endIndex >= matchingFoods.length}
                        >
                            &gt;
                        </button>
                    </div>
                </div>
            ) : (
                <p>No food suggestions available for this phase.</p>
            )}
        </div>
    );
};

export default Food;


