import React, { useState, useEffect } from "react";
import FooterMenu from "../components/FooterMenu";
import CurrentDay from "../components/CurrentDay";

//style
import '../components/styleSheets/sk.css';
const BG_WEB = "https://greattakes.s3.us-east-2.amazonaws.com/siteAssets/bg_web1.png";

const Home = () => {
    return (
        <div style={{ backgroundImage: `url(${BG_WEB})`, width: "100vw", height: "100vh" }}>
            <div className="opener">
                BALANCE YOUR HORMONES, BALANCE YOUR LIFE.
                <CurrentDay />
            </div>


            <FooterMenu />

        </div>
    );
};

export default Home;
