import React, { useEffect, useState } from "react";
import "../components/styleSheets/sk.css";

import FooterMenu from "./FooterMenu";

const Recipes = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/recipe/data`);
                const result = await response.json();
                if (response.ok) {
                    setData(result);
                } else {
                    console.error("Failed to fetch data:", result.message);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false); // Set loading to false regardless of success or failure
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!data || data.length === 0) {
        return <div>No recipes available.</div>;
    }

    const featuredRecipe = data[0]; // Assume the first recipe is the featured one
    const otherRecipes = data.slice(1);

    return (
        <div style={{ backgroundColor: '#8da9c4' }}>
            {/* Featured Recipe */}
            {featuredRecipe && featuredRecipe.banner ? (
                <div className="featured-recipe">
                    <img
                        src={`https://greattakes.s3.us-east-2.amazonaws.com/siteAssets/${featuredRecipe.banner}`}
                        alt={featuredRecipe.title || "Featured Recipe"}
                        className="featured-image"
                    />
                    <div className="featured-info">
                        <h2>{featuredRecipe.title || "Featured Recipe"}</h2>
                        <a href={`/recipe/${featuredRecipe.id}`} className="read-more">
                            READ MORE →
                        </a>
                    </div>
                </div>
            ) : (
                <div className="featured-placeholder">
                    <p>No featured recipe available.</p>
                </div>
            )}

            {/* Recipes Grid */}
            <div className="recipes-grid">
                {otherRecipes.map((recipe) => (
                    <div key={recipe.id} className="recipe-card">
                        <img
                            src={`https://greattakes.s3.us-east-2.amazonaws.com/siteAssets/${recipe.img}`}
                            alt={recipe.title}
                            className="recipe-image"
                        />
                        <h3>{recipe.title}</h3>
                    </div>
                ))}
            </div>

            <FooterMenu />
        </div>
    );
};

export default Recipes;
