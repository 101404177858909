import React from 'react';
import { createBrowserRouter, RouterProvider, Route } from 'react-router-dom';
import { Home } from './pages/index';
import Recipes from './components/Recipes'
// import { Recipes } from './components/Recipes';

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Home />
    },

    {
      path: "/recipes",
      element: <Recipes />,
    },

  ],

  {
    future: {
      v7_startTransition: true, // Opt-in to React.startTransition behavior
      v7_normalizeFormMethod: true, // Normalize formMethod casing to uppercase
      v7_partialHydration: true, // Enable partial hydration for better performance
      v7_skipActionErrorRevalidation: true, // Skip revalidation after 4xx/5xx action errors
      v7_relativeSplatPath: true, // Update relative route resolution within splat routes
      v7_fetcherPersist: true, // Update fetcher persistence behavior
      v7_startTransition: true,
    },
  }
);

function App() {
  return (
    <div >
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
