import React, { useEffect, useState } from "react";
import "../components/styleSheets/sk.css"

import FooterMenu from "./FooterMenu";

const Recipes = () => {
    const [data, setData] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/recipe/data`);
                const result = await response.json();
                if (response.ok) {
                    setData(result);
                } else {
                    console.error("Failed to fetch data:", result.message);
                }
            } catch (error) {
                console.error("Error fetching data:", error);

            }
        };
        console.log("Fetching from URL:", `${process.env.REACT_APP_SERVER_URL}/recipe/data`);

        fetchData();
    }, []);

    return (
        <div>
            <div className="bodyStacked">
                <p>Proof navigation is working</p>

                {data.length > 0 ? (
                    data.map((recipe) => (
                        <div key={recipe._id} className="recipe-card">
                            <h2 className="recipe-title">{recipe.title}</h2>
                            <p><strong>Phase:</strong> {recipe.phase}</p>
                            <p><strong>Prep Time:</strong> {recipe.prepTime || "Not specified"}</p>
                            <p><strong>Cook Time:</strong> {recipe.cookTime || "Not specified"}</p>
                            <p><strong>Yield:</strong> {recipe.yield || "Not specified"}</p>
                            <p><strong>Category:</strong> {recipe.category}</p>

                        </div>
                    ))
                ) : (
                    <p>Loading recipes...</p>
                )}
            </div>
            <FooterMenu />
        </div>
    );
};

export default Recipes;