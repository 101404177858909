import React from "react";
import { useNavigate } from "react-router-dom";
import { FaCalendarDay, FaRegCalendarAlt, FaSearch, FaEllipsisH } from 'react-icons/fa';
import '../components/styleSheets/sk.css'

const FooterMenu = () => {

    const navigate = useNavigate();

    const ClickToHome = () => {
        navigate("/", { replace: true });
        window.location.reload();
    };

    const ClickToRecipes = () => {
        navigate("/recipes", { replace: true });
        window.location.reload();
    };

    return (
        <div>
            <div className="bottom-menu">
                <div className="menu-item" onClick={ClickToHome}>
                    <FaCalendarDay className="menu-icon" />
                    <span className="menu-label">Today</span>
                </div>
                <div className="menu-item">

                    <FaRegCalendarAlt className="menu-icon" />
                    <span className="menu-label">Plan</span>
                </div>
                <div className="menu-item" onClick={ClickToRecipes}>
                    <FaSearch className="menu-icon" />
                    <span className="menu-label">Recipes</span>
                </div>
                <div className="menu-item">
                    <FaEllipsisH className="menu-icon" />
                    <span className="menu-label">More</span>
                </div>
            </div>
        </div>
    )
}
export default FooterMenu