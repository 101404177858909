import React from "react";
import FooterMenu from "../components/FooterMenu";
import CurrentDay from "../components/CurrentDay";

//style
import '../components/styleSheets/sk.css';

const Home = () => {
    return (
        <div>
            <div className="opener">
                BALANCE YOUR HORMONES, BALANCE YOUR LIFE.
                <CurrentDay />
            </div>
            <FooterMenu />
        </div>
    );
};

export default Home;
